<template>
  <div class="ipbm">
    <div
      class="ipbm__container"
      :class="[isWithoutData && 'ipbm__container--without-data']"
    >
      <div class="ipbm__loader">
        <img src="@/assets/images/message-center/import-loading-animation.gif" alt="loading" />
      </div>

      <div class="ipbm__info">
        <div class="ipbm__info-text-block">
          <ar-text
            :text="progress !== 0 || isWithoutData ? 'Importing your contacts' : `Preparing to import`"
            size="16"
            weight="bold"
            :style="{
              'color': '#43516B'
            }"
          />

          <ar-text
            v-if="!isWithoutData"
            :text="`${progress}%`"
            size="16"
            weight="bold"
            :style="{
              'color': '#43516B'
            }"
          />
        </div>

        <div v-if="!isWithoutData" class="ipbm__info-text-block">
          <ar-text
            :text="`Contacts added `"
            size="14"
            :style="{
              'color': '#8492A6'
            }"
          />

          <ar-text
            :text="rowsAdded"
            size="14"
            :style="{
              'color': '#8492A6'
            }"
          />
        </div>

        <div v-if="!isWithoutData" class="ipbm__info-text-block">
          <ar-text
            :text="`Excluded contacts `"
            size="14"
            :style="{
              'color': '#8492A6'
            }"
          />

          <ar-text
            :text="excluded"
            size="14"
            :style="{
              'color': '#8492A6'
            }"
          />
        </div>

        <div v-if="!isWithoutData" class="ipbm__info-text-block">
          <ar-text
            :text="`Total contacts`"
            size="14"
            :style="{
              'color': '#8492A6'
            }"
          />

          <ar-text
            :text="totalRows"
            size="14"
            :style="{
              'color': '#8492A6'
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  name: "ImportProgressBarModal",
  props: {
    progress: {
      type: Number,
      default: 0
    },
    rowsAdded: {
      type: Number,
      default: 0
    },
    excluded: {
      type: Number,
      default: 0
    },
    totalRows: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isWithoutData() {
      return this.totalRows === 0;
    }
  }
})
</script>

<style scoped lang="scss">
.ipbm {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100;

  &__container {
    width: 348px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -4px rgba(0, 0, 0, 0.10);
    padding: 24px;
    display: flex;
    flex-direction: column;

    &--without-data {
      width: unset;
    }
  }

  &__loader {
    width: 88px;
    height: 88px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__info-text-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
