<template>
  <section
    :class="[
      'customer-profile',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    ]"
  >
    <am2-unlock-prompt
      v-if="!isCustomerProfileFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Customer Profiles` : `Customer Profiles`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `Get closer to your audience by getting to know your top fans. <a href='https://www.audiencerepublic.com/features/audience-crm' target='_blank' style='color: #FFF;'>Learn more</a>` : `Get to know your top fans. <a href='https://www.audiencerepublic.com/features/audience-crm' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />
    <am2-hero
      :back-path="sanitizedReferringPage"
      :title="customerFormattedFullName"
      :membership-avatars="membershipAvatars"
      :image="profileImageExists ? customerState.customer.profileImageUrl : null"
      :customer-age="customerFormattedAge"
      :location="customerFormattedLocation"
      is-customer
    />

    <am2-navbar
      :menu="menu"
      v-ar-sticky-top="{
        priority: 1,
      }"
    >
      <!-- TODO - Add Message button -->
      <!-- TODO - Add Settings button -->
    </am2-navbar>

    <nuxt-child
      class="content content--centered"
      @fan-data-change="changeUserData"
    />
  </section>
</template>

<script>

import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { countriesWithStateSelectors } from '@/utils/countries';

export default {
  name: 'FanProfile',
  props: {
  },
  data: () => ({
    countriesWithStateSelectors: countriesWithStateSelectors,
  }),
  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      customerFormattedLocation: 'customer/customerFormattedLocation',
      customerFormattedAge: 'customer/customerFormattedAge',
      customerFormattedFullName: 'customer/customerFormattedFullName',
      historicalPreviousPagePath: 'application/historicalPreviousPagePath',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    ...mapState({
      customerState: state => state.customer,
      fanLoyaltyMemberships: state => state.customer.fanLoyaltyMemberships,
      loyaltyProgramTiersMap: state => state.customer.loyaltyProgramTiersMap,
      customerCustomFields: state => state.customer.customerCustomFields,
    }),

    sanitizedReferringPage() {
      return this.historicalPreviousPagePath('/audience');
    },
    membershipAvatars() {
      return this.fanLoyaltyMemberships.map(item => {
        return {
          iconName: item.provider,
          iconColor: this.$arStyle.color[item.provider],
          tooltip: this.getLoyaltyTierTitle(item),
        };
      });
    },
    menu() {
      if (this.isPermissionEnabled(['view-sales'])) {
        return {
          overview: {
            name: 'Overview',
            to: `/audience/${this.$route.params.oid}/view/overview`,
          },
          purchases: {
            name: 'Purchases',
            to: `/audience/${this.$route.params.oid}/view/purchases`,
          },
          events: {
            name: 'Events',
            to: `/audience/${this.$route.params.oid}/view/events`,
          },
          campaigns: {
            name: 'Campaigns',
            to: `/audience/${this.$route.params.oid}/view/campaigns`,
          },
          activity: {
            name: 'Activity',
            to: `/audience/${this.$route.params.oid}/view/activity`,
          },
        };
      } else {
        return {
          overview: {
            name: 'Overview',
            to: `/audience/${this.$route.params.oid}/view/overview`,
          },
          events: {
            name: 'Events',
            to: `/audience/${this.$route.params.oid}/view/events`,
          },
          campaigns: {
            name: 'Campaigns',
            to: `/audience/${this.$route.params.oid}/view/campaigns`,
          },
          activity: {
            name: 'Activity',
            to: `/audience/${this.$route.params.oid}/view/activity`,
          },
        };
      }
    },
    isCustomerProfileFeatureEnabled() {
      return this.isFeatureEnabled(['customer-profiles']);
    },
    profileImageExists() {
      return false;
      // TODO - Need a better way to check this.
      if (!this.customerState?.customer?.profileImageUrl) return false;
      const http = new XMLHttpRequest();
      http.open('HEAD', this.customerState?.customer?.profileImageUrl, false);
      http.send();
      return http.status !== 404;
    }
  },

  beforeDestroy() {
    this['customer/RESET_CUSTOMER_DATA']();
  },

  methods: {
    ...mapActions([
      'customer/FETCH_CUSTOMER',
      'customer/FETCH_CUSTOMER_CUSTOM_FIELDS',
      'customField/FETCH_CUSTOM_FIELDS',
      'MASS_EDIT_FAN',
      'auth/FETCH_PERMISSIONS',
    ]),
    ...mapMutations([
      'customer/RESET_CUSTOMERS',
      'customer/RESET_CUSTOMER_DATA',
      'customer/UPDATE_CUSTOMER',
    ]),
    getLoyaltyTierTitle(loyaltyMembership) {
      const loyaltyTier = this.loyaltyProgramTiersMap[loyaltyMembership.fanLoyaltyTier.loyaltyTierOid];
      switch (loyaltyMembership.provider) {
        case 'patreon':
          return `${this.customerState.customer.name} is a Patreon member — ${loyaltyTier ? loyaltyTier.title : 'Custom pledge'}`;
        default:
          return null;
      }
    },
    // Checks to see whether the state value is driven by a string input or a select field
    hasStateSelect(countyIsoCode) {
      if (!countyIsoCode) return false;
      return this.countriesWithStateSelectors.indexOf(countyIsoCode) > -1;
    },

    // When user data is updated, MASS EDIT the fan, then make sure to update the corresponding values in the customer vuex store
    async changeUserData(changes) {
      if (!this.$route.params.oid) return;
      try {
        await this.MASS_EDIT_FAN({
          fanOids: [this.$route.params.oid],
          editData: {
            ...changes
          },
        });
        this['customer/UPDATE_CUSTOMER'](changes);
      } catch (e) {
        console.log(e);
        this.$arNotification.push({ type: 'error', message: `There was a problem processing your request` });
      }
    },

    async goToPlans() {
      this.$router.push({
        path: `/plans`,
      });
    },

    // Fetch the fan matching the route's oid.
    // If no fan matching this oid can be found, lets take the user back to the audience page
    async fetchFan() {
      if (this.customerState.isFetchingCustomer) {
        return;
      }

      this['customer/RESET_CUSTOMERS']();
      await this['customer/FETCH_CUSTOMER']({fanOid: this.$route.params.oid});


      if (!this.customerState.customer) {
        this.$router.push({
          path: `/audience`,
        });
      }
    },
    async fetchCustomFields() {
      if (this.customerState.isFetchingCustomerCustomFields){
        return;
      }

      await this['customer/FETCH_CUSTOMER_CUSTOM_FIELDS']({fanOid: this.$route.params.oid});
      await this['customField/FETCH_CUSTOM_FIELDS']({type: 'profile' })
    }
  },

  mounted() {
    this.fetchFan();
    this.fetchCustomFields();
    this['auth/FETCH_PERMISSIONS']()
  },
};


</script>

<style lang="scss" scoped>
  .content {
    padding: 30px;
  }

</style>
