var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ipbm"},[_c('div',{staticClass:"ipbm__container",class:[_vm.isWithoutData && 'ipbm__container--without-data']},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"ipbm__info"},[_c('div',{staticClass:"ipbm__info-text-block"},[_c('ar-text',{style:({
            'color': '#43516B'
          }),attrs:{"text":_vm.progress !== 0 || _vm.isWithoutData ? 'Importing your contacts' : "Preparing to import","size":"16","weight":"bold"}}),_vm._v(" "),(!_vm.isWithoutData)?_c('ar-text',{style:({
            'color': '#43516B'
          }),attrs:{"text":(_vm.progress + "%"),"size":"16","weight":"bold"}}):_vm._e()],1),_vm._v(" "),(!_vm.isWithoutData)?_c('div',{staticClass:"ipbm__info-text-block"},[_c('ar-text',{style:({
            'color': '#8492A6'
          }),attrs:{"text":"Contacts added ","size":"14"}}),_vm._v(" "),_c('ar-text',{style:({
            'color': '#8492A6'
          }),attrs:{"text":_vm.rowsAdded,"size":"14"}})],1):_vm._e(),_vm._v(" "),(!_vm.isWithoutData)?_c('div',{staticClass:"ipbm__info-text-block"},[_c('ar-text',{style:({
            'color': '#8492A6'
          }),attrs:{"text":"Excluded contacts ","size":"14"}}),_vm._v(" "),_c('ar-text',{style:({
            'color': '#8492A6'
          }),attrs:{"text":_vm.excluded,"size":"14"}})],1):_vm._e(),_vm._v(" "),(!_vm.isWithoutData)?_c('div',{staticClass:"ipbm__info-text-block"},[_c('ar-text',{style:({
            'color': '#8492A6'
          }),attrs:{"text":"Total contacts","size":"14"}}),_vm._v(" "),_c('ar-text',{style:({
            'color': '#8492A6'
          }),attrs:{"text":_vm.totalRows,"size":"14"}})],1):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ipbm__loader"},[_c('img',{attrs:{"src":require("@/assets/images/message-center/import-loading-animation.gif"),"alt":"loading"}})])}]

export { render, staticRenderFns }