<template>
  <ar-modal
    id="mass-event-data-modal"
    :is-open="isVisible"
    class="import-data-modal"
    :header="header"
    :has-back-link="step > 1 || (step === 1 && isMassImport)"
    :hide-footer="step === 1"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
    @back="previousStep"
  >
    <!-- BODY -->
    <div
      v-if="step === 0"
      slot="body"
      :class="['import-data-modal__wrapper', 'generic', $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3']"
    >
      <div
        v-for="(option, index) in massImportOptions"
        :key="index"
        :class="['mass-import-option', $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max']"
        @click="() => (option === 'POS-line-items' ? toggleProductImportModel(option) : selectType(option))"
      >
        <div :class="['icon-container', $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max']">
          <ar-icon
            v-if="option === 'events and orders'"
            name="calendar-ticket-combined"
            :width="$arMediaQuery.pageContent.maxWidth('xs') ? '30px' : '38px'"
            :height="$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '31px'"
            :style="{
              position: 'relative',
              top: $arMediaQuery.pageContent.maxWidth('xs') ? '2px' : '3px',
              left: $arMediaQuery.pageContent.maxWidth('xs') ? '2px' : '3px',
            }"
            color="#7344c0"
          />
          <ar-icon
            v-else-if="option === 'events'"
            name="calendar"
            :width="$arMediaQuery.pageContent.maxWidth('xs') ? '20px' : '25px'"
            :height="$arMediaQuery.pageContent.maxWidth('xs') ? '20px' : '25px'"
            color="#7344c0"
          />
          <ar-icon
            v-else
            name="ticket"
            :width="$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '30px'"
            :height="$arMediaQuery.pageContent.maxWidth('xs') ? '24px' : '30px'"
            color="#7344c0"
          />
        </div>
        <div
          :class="['mass-import-option__description', $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-right-7']"
        >
          <p class="heading">{{ optionTitleDescription(option, true) }}</p>
          <p class="body">{{ optionTitleDescription(option, false) }}</p>
        </div>
        <div :class="$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'">
          <span>&#10095;</span>
        </div>
      </div>
    </div>
    <div
      v-else-if="step === 1"
      slot="body"
      :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3']"
    >
      <p class="import-data-modal__wrapper_preamble">
        Upload
        {{
          type === 'audience' || type === 'orders'
            ? 'orders'
            : type === 'events'
            ? 'multiple events'
            : 'multiple events and orders'
        }}
        from your ticketing provider.
      </p>

      <am2-dropzone
        class="u-margin-top-5"
        :file-type="['text/csv']"
        :file-size="100000000"
        file-type-alias="CSV file"
        :placeholder-icon="{
          name: 'upload',
          width: '16px',
          height: '16px',
          color: $arStyle.color.purple500,
          wrapperStyle: {
            background: mouseOverCsvDropdown ? `white` : $arStyle.color.skyBlueGrey400,
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: mouseOverCsvDropdown ? '2px solid white' : null,
          },
        }"
        :ratio="0.525"
        @upload="handleCSVUploaded"
        :data-test-id="`import-dropzone`"
        @mouseover.native="mouseOverCsvDropdown = true"
        @mouseleave.native="mouseOverCsvDropdown = false"
      />
    </div>
    <div v-else-if="step === 2" slot="body">
      <div :class="['mapping-instructions', $arMediaQuery.window.maxWidth('xs') && 'u-padding-3']">
        <p>Map the fields to import into Audience Republic from your CSV file</p>
        <p class="mapping-instructions__subtitle">* indicates a compulsory field</p>
      </div>
      <am2-tabs
        v-if="type === 'events and orders'"
        class="tabs"
        :items="[
          { id: 'events', title: 'Event information' },
          { id: 'orders', title: 'Order information' },
        ]"
        @select="changeMapping"
      />
      <div
        :class="[
          'import-data-modal__wrapper',
          $arMediaQuery.window.maxWidth('xs') && 'xs-max',
          type === 'events and orders' ? 'eventsAndOrders' : 'notEventAndOrders',
        ]"
      >
        <p class="column-text"><span>Audience Republic</span><span>CSV File</span></p>

        <div v-if="type === 'events and orders'" class="form">
          <div v-for="(arTableHeader, index) in visibleHeaderSelections" :key="index" class="form-row">
            <p class="col-xs-12 col-sm-5 form-row__label">
              {{ `${arTableHeader.value}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{
                  content: arTableHeader.hint,
                }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              />
            </p>

            <div class="col-xs-12 col-sm-7 form-row__input">
              <!--
                RK/NOTE: the currency code doesn't go in the mapping,
                therefore it is easier to put it in after the appropriate
                field instead of using the same logic as the other mappings
              -->
              <ar-simple-select
                v-if="arTableHeader.value === 'Currency'"
                enable-filter
                class="selector"
                :items="currencyOptions"
                placeholder="Please select..."
                enable-clear
                :default-select-index="actualSelectedCurrencyIndex(arTableHeader.mappedTo)"
                :data-test-id="`import-option-${arTableHeader.value}`"
                @select="selectCurrency"
                @clear="() => selectCurrency(null)"
              />

              <ar-simple-select
                v-else-if="arTableHeader.value === 'Date Format'"
                class="selector"
                :items="dateFormatOptions"
                :default-select-index="selectedDateFormatIndex"
                :data-test-id="`import-option-${arTableHeader.value}`"
                @select="selectDateformat"
              />

              <am2-timezone-select
                v-else-if="arTableHeader.value === 'Timezone'"
                v-model="selectedTimezone"
                placeholder="Please select..."
                :data-test-id="`import-option-${arTableHeader.value}`"
                class="selector"
              />

              <ar-simple-select
                v-else
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :data-test-id="`import-option-${arTableHeader.value}`"
                :default-select-index="actualSelectedIndex(arTableHeader.mappedTo)"
                @select="(val) => setMapping(val, arTableHeader)"
                @clear="() => setMapping({ index: null }, arTableHeader)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
              <div v-else-if="arTableHeader.value === 'Currency' && currencyMissing" class="error">
                This field is required.
              </div>
            </div>
          </div>
          <div
            v-for="(arTableHeader, index) in visibleProviderSpecificHeaderSelections"
            :key="`${index}-provider-mapping`"
            class="form-row"
          >
            <p class="col-xs-12 col-sm-5 form-row__label">
              {{ `${arTableHeader.value}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{
                  content: arTableHeader.hint,
                }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              />
            </p>
            <div class="col-xs-12 col-sm-7 form-row__input">
              <ar-simple-select
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :data-test-id="`import-option-${arTableHeader.value}`"
                :default-select-index="actualSelectedIndex(arTableHeader.mappedTo)"
                @select="(val) => setMapping(val, arTableHeader, true)"
                @clear="() => setMapping({ index: null }, arTableHeader, true)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
              <div v-else-if="arTableHeader.value === 'Currency' && currencyMissing" class="error">
                This field is required.
              </div>
            </div>
          </div>

          <div v-if="arEnableCustomFields && isCustomFieldsFeatureEnabled && activeMapping === 'orders'" class="custom-field-map">
            <div
              v-for="(customField, customFieldIndex) in customFieldMap"
              class="form-row"
              :key="'custom-field-row-' + customFieldIndex"
            >

              <p class="col-xs-12 col-sm-5 form-row__label">
                {{  customField.name }}
                <ar-icon
                  v-if="customField.hint && $arMediaQuery.window.minWidth('sm')"
                  v-tooltip.top="{ content: customField.hint }"
                  name="tooltip-question-mark"
                  class="icon"
                  height="15px"
                  width="15px"
                />
              </p>
              <div class="col-xs-12 col-sm-7 form-row__input">
                <ar-simple-select
                  :items="csvHeadersOptions(customField.mappedTo)"
                  :default-select-index="actualSelectedIndex(customField.mappedTo)"
                  class="selector"
                  enable-filter
                  enable-clear
                  placeholder="Please select..."
                  :data-test-id="`csv-colum-custom-field-${customField.oid}`"
                  @select="(val) => setMapping(val, customField)"
                  @clear="() => setMapping({ index: null }, customField)"
                />
                <ar-icon
                  class="delete-button u-margin-left-4"
                  name="discard"
                  height="16px"
                  :color="$arStyle.color.blueGrey600"
                  @click="(optionRow) => handleDeleteCustomField(customField)"
                />
              </div>
            </div>
          </div>


          <div v-if="arEnableCustomFields && isCustomFieldsFeatureEnabled && activeMapping === 'orders'" class="simple-button-filtered-dropdown-wrapper">
            <am2-simple-button-filtered-dropdown
              class="simple-button-filtered-button"
              text="Add custom field"
              icon-name="plus"
              item-name="custom fields"
              :items="filteredCustomFields"
              @select="addCustomFieldToFieldMap"
            />
          </div>
        </div>

        <div class="form" v-else>
          <div v-for="(arTableHeader, index) in defaultFieldMap" :key="index" class="form-row">
            <p class="col-xs-12 col-sm-5 form-row__label">
              {{ `${arTableHeader.value}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{
                  content: arTableHeader.hint,
                }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              />
            </p>

            <div class="col-xs-12 col-sm-7 form-row__input">
              <!--
                RK/NOTE: the currency code doesn't go in the mapping,
                therefore it is easier to put it in after the appropriate
                field instead of using the same logic as the other mappings
              -->
              <ar-simple-select
                v-if="arTableHeader.value === 'Currency'"
                class="selector"
                enable-filter
                :items="currencyOptions"
                :default-select-index="selectedCurrencyIndex"
                :data-test-id="`import-option-${arTableHeader.value}`"
                placeholder="Please select..."
                enable-clear
                @select="selectCurrency"
                @clear="() => selectCurrency(null)"
              />

              <ar-simple-select
                v-else-if="arTableHeader.value === 'Date Format'"
                class="selector"
                :items="dateFormatOptions"
                :default-select-index="selectedDateFormatIndex"
                :data-test-id="`import-option-${arTableHeader.value}`"
                @select="selectDateformat"
              />

              <am2-timezone-select
                v-else-if="arTableHeader.value === 'Timezone'"
                v-model="selectedTimezone"
                :data-test-id="`import-option-${arTableHeader.value}`"
                :placeholder="'Please select...'"
                class="selector"
              />

              <ar-simple-select
                v-else
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :data-test-id="`import-option-${arTableHeader.value}`"
                :default-select-index="actualSelectedIndex(arTableHeader.mappedTo)"
                @select="(val) => setMapping(val, arTableHeader, false)"
                @clear="() => setMapping({ index: null }, arTableHeader, false)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
              <div v-else-if="arTableHeader.value === 'Currency' && currencyMissing" class="error">
                This field is required.
              </div>
            </div>
          </div>
          <div
            v-for="(arTableHeader, index) in providerSpecificMap"
            :key="`${index}-provider-mapping`"
            class="form-row"
          >
            <p class="col-xs-12 col-sm-5 form-row__label">
              {{ `${arTableHeader.value}${arTableHeader.required ? '*' : ''}` }}
              <ar-icon
                v-if="arTableHeader.hint && $arMediaQuery.window.minWidth('sm')"
                v-tooltip.top="{
                  content: arTableHeader.hint,
                }"
                name="tooltip-question-mark"
                class="icon"
                height="15px"
                width="15px"
              />
            </p>
            <div class="col-xs-12 col-sm-7 form-row__input">
              <ar-simple-select
                class="selector"
                enable-filter
                :items="csvHeadersOptions(arTableHeader.mappedTo)"
                placeholder="Please select..."
                enable-clear
                :data-test-id="`import-option-${arTableHeader.value}`"
                :default-select-index="actualSelectedIndex(arTableHeader.mappedTo)"
                @select="(val) => setMapping(val, arTableHeader, true)"
                @clear="() => setMapping({ index: null }, arTableHeader, true)"
              />

              <div v-if="arTableHeader.error" class="error">
                {{ arTableHeader.error }}
              </div>
              <div v-else-if="arTableHeader.value === 'Currency' && currencyMissing" class="error">
                This field is required.
              </div>
            </div>
          </div>


          <div v-if="showCustomFields" class="custom-field-map">
            <div
              v-for="(customField, customFieldIndex) in customFieldMap"
              class="form-row"
              :key="'custom-field-row-' + customFieldIndex"
            >

              <p class="col-xs-12 col-sm-5 form-row__label">
                {{  customField.name }}
                <ar-icon
                  v-if="customField.hint && $arMediaQuery.window.minWidth('sm')"
                  v-tooltip.top="{ content: customField.hint }"
                  name="tooltip-question-mark"
                  class="icon"
                  height="15px"
                  width="15px"
                />
              </p>
              <div class="col-xs-12 col-sm-7 form-row__input">
                <ar-simple-select
                  :items="csvHeadersOptions(customField.mappedTo)"
                  :default-select-index="actualSelectedIndex(customField.mappedTo)"
                  class="selector"
                  enable-filter
                  enable-clear
                  placeholder="Please select..."
                  :data-test-id="`csv-colum-custom-field-${customField.oid}`"
                  @select="(val) => setMapping(val, customField)"
                  @clear="() => setMapping({ index: null }, customField)"
                />
                <ar-icon
                  class="delete-button u-margin-left-4"
                  name="discard"
                  height="16px"
                  :color="$arStyle.color.blueGrey600"
                  @click="(optionRow) => handleDeleteCustomField(customField)"
                />
              </div>
            </div>
          </div>


          <div v-if="showCustomFields" class="simple-button-filtered-dropdown-wrapper">
            <am2-simple-button-filtered-dropdown
              class="simple-button-filtered-button"
              text="Add custom field"
              icon-name="plus"
              item-name="custom fields"
              :items="filteredCustomFields"
              @select="addCustomFieldToFieldMap"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="step === 3"
      slot="body"
      :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3']"
    >
      <p class="column-text"><span>Audience Republic</span><span>CSV File</span></p>

      <p class="import-data-modal__wrapper__import-message">
        {{ importCompleteMessage }}
      </p>
    </div>
    <div v-else-if="step === 10" slot="body" class="import-data-modal__fields-options">
      <EventsImportFields
        :type-of-import="typeOfImportModal"
        :available-providers="availableProviders"
        :default-selected-index="selectProviderIndex"
        :selectedProvider="selectedProvider"
        @select="selectProvider"
        @create-provider="createProvider"
        @close="onClose"
      />
    </div>

    <!-- FOOTER -->
    <div v-if="step >= 2" slot="footer" class="import-data-modal__footer">
      <!--
        NOTE/RK: Bring this back when website copy is sorted.
        <div v-if="step===1">
          <div v-if="isMassImport" style="text-align: center">
            <a
              href="/events/new"
            >
              Manually create an event
            </a>
          </div>
          <div v-else>
            <p class="header">Want to easily import your sales data?</p>
            <p class="body">Our integrations help you seamlessly sync your ticket sales data.
              <a
                target="_blank"
                href="https://www.audiencerepublic.com/features/audience-crm/">
                Learn more
              </a>
            </p>
          </div>
        </div>
      -->
      <div class="footer-container" v-if="step === 2">
        <div>
          <div v-if="error" class="error-message" v-html="error" />
          <div :class="['footer-controls', $arMediaQuery.window.maxWidth('xs') && 'u-padding-3']">
            <div
              :class="['ticketing-provider', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
              :style="{ display: 'inline flex', 'align-content': 'flex-start', 'align-items': 'flex-start' }"
            >
              <div v-if="selectedProvider" class="tick-container">
                <ar-icon name="check" height="1em" width="1.5em" color="white" />
              </div>
              <ar-text
                size="xs"
                text="Ticketing provider:"
                :style="{
                  marginLeft: $arMediaQuery.window.maxWidth('xs') ? '5px' : '10px',
                  marginBottom: $arMediaQuery.window.maxWidth('xs') ? '12px' : '0',
                }"
              />
              <div class="col-xs-12">
                <ProviderChooser
                  class="provider-chooser"
                  :items="availableProviders"
                  @select="selectProvider"
                  @create-provider="createProvider"
                  :default-selected-index="selectProviderIndex"
                />
                <div v-if="providerReminder" class="error">Please select a provider.</div>
              </div>
            </div>
            <ar-simple-button
              :text="importButtonText"
              :loading="isImportingData"
              @click="beginImport"
              :type="canImport ? 'purple' : 'grey'"
              :style="{ float: 'right' }"
            />
          </div>
        </div>
      </div>

      <div class="footer-container" v-else-if="step === 3">
        <p v-if="error">
          {{ error }}
        </p>
        <ar-simple-button text="Close" @click="onClose" :style="{ float: 'right' }" />
      </div>
    </div>
  </ar-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  CURRENCY_CODES,
  defaultTicketingProviders,
  initialAudienceCSVMap,
  initialEventsCSVMap,
} from '~/utils/constants';

import {
  capitalizeFirstLetter,
  clone,
  csvValidationCheck,
  fileContentZipToB64,
  generateUniqueIntegersArray,
  sanitizeFilename
} from '@/utils/helpers';
import ProviderChooser from './ProviderChooser';
import EventsImportFields from './EventsImportFields';

// XXX/RK/27-05-2019
//
// NOTE: we're using moment's strict parsing mode to
// ensure that the dates in the promoter's CSV are compatible
// with the date-parsing on the server. Strict mode is very
// picky by nature; more so than our serverside clj-time parser.
//
// One of the major "gotcha's" is single-digit values. E.g., if
// a column value for a date has day "8" instead of "08",
// `moment` would consider that date invalid even though the server
// can process a date-string like 8/12/2000 perfectly fine as
// the 8th of December, 2000.
//
// So when adding formats to this list, ensure that you've accounted for
// single-digit values for hour, day, and month when applicable.

export default {
  name: 'ImportEventDataModal',

  components: {
    ProviderChooser,
    EventsImportFields,
  },

  data() {
    return {
      step: 1,
      type: 'audience',
      activeMapping: 'events',
      arToPromoterMap: initialAudienceCSVMap(),
      defaultProviders: defaultTicketingProviders(),
      providerSpecificMap: [],
      selectedCurrency: null,
      selectedTimezone: null,
      selectedDateFormat: {name: 'International', val: 'international'},
      csvHeaders: [],
      csvRows: [],
      currencyOptions: CURRENCY_CODES.map((c) => ({ name: c.code })),
      dateFormatOptions: [{name: 'International', val: 'international'}, {name: 'US', val: 'us'}],
      error: '',
      rowErrors: false,
      // NOTE/RK: things below here used to be props
      isVisible: false,
      eventOid: null,
      eventTimeZone: null,
      isMassImport: false,
      isImportingData: false,
      // providers
      isProviderChooserOpen: false,
      availableProviders: [],
      selectedProvider: null,
      providerReminder: false,
      currencyMissing: null,
      mouseOverCsvDropdown: false,
      importFile: null,
      indexesToValidate: [], // Keep it consistent so that we don't recompute this every time the user presses Next
      onConfirm: () => {},
      onClose: () => {},
      arEnableCustomFields: process.env.arEnableCustomFields,
      typeOfImportModal: '',
    };
  },
  computed: {
    ...mapState({
      promoterOid: (state) => state.auth.account.promoterOid,
      customFields: (state) => state.customField.customFields,
      currentRegionSettings: state => state.auth.regionSettings,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    isOneSchemaEnabled() {
      return this.isFeatureEnabled(['oneschema']);
    },
    header() {
      if (this.step === 0) {
        return 'Mass import';
      }
      switch (this.type) {
        case 'audience':
          return 'Import ticket sales';
        case 'events':
          return 'Import events';
        case 'orders':
          return 'Import orders';
        case 'events and orders':
          return 'Import events & orders';
        case 'POS-line-items':
          return 'Import events and POS orders';
        default:
          return 'Mass import';
      }
    },
    importButtonText() {
      if (this.rowErrors) return "Import with warnings";
      return "Import";
    },
    importCompleteMessage() {
      return this.$store.state.importMessage;
    },
    massImportOptions: () => ['events', 'events and orders', 'POS-line-items'],
    selectProviderIndex() {
      // NOTE/RK: don't do `index = null;`
      // doing `index = -1` avoids having to do a null check
      // in the return statement since null >= 0 is true
      let index = -1;
      if (this.selectedProvider) {
        index = this.availableProviders.findIndex((a) => a.name.toLowerCase() === this.selectedProvider.toLowerCase());
      }

      return index >= 0 ? index : null;
    },
    selectedCurrencyIndex() {
      if (!this.selectedCurrency) {
        return null
      }

      const currencyCode = this.selectedCurrency.name;
      const index = this.currencyOptions.findIndex((c) => c.name === currencyCode);
      return (index === -1) ? null : index;
    },
    selectedDateFormatIndex() {
      const dateFormat = this.selectedDateFormat.name;
      const index = this.dateFormatOptions.findIndex((c) => c.name === dateFormat);
      return index === -1 ? null : index;
    },
    visibleHeaderSelections() {
      return this.arToPromoterMap.filter((mapHeader) => mapHeader.relatesTo === this.activeMapping);
    },
    // This will only return the providerSpecificMap options which are relevant to the selected tab (denoted by relatesTo).
    // If the option is required and includes this import type in enabledFor, it will still be required, even if its not
    // visible.
    visibleProviderSpecificHeaderSelections() {
      return this.providerSpecificMap.filter((mapHeader) => mapHeader.relatesTo === this.activeMapping);
    },
    canImport() {
      if (!this.arToPromoterMap ||!this.selectedProvider) return false
      const unmappedRequired = this.arToPromoterMap.filter(item => item.required && (item.mappedTo === null ))
      if (this.type === 'events') {
        return this.selectedTimezone && unmappedRequired.length === 0;
      } else {
        return this.selectedCurrency && this.selectedTimezone && unmappedRequired.length === 0;
      }
    },
    filteredCustomFields() {
      return this.customFields.filter(customField => {
        const customFieldMapIndex = this.customFieldMap.findIndex(customFieldMapItem => {
          return customField.oid.toString() === customFieldMapItem.serverHeaderName.replace("cf_", "")
        })

        return customField.fieldType != 'dropdown-multi' && customFieldMapIndex < 0
      })
    },
    customFieldMap() {
      return this.arToPromoterMap.filter(item => !!item.customField)
    },
    defaultFieldMap() {
      return this.arToPromoterMap.filter(item => !item.customField)
    },
    isCustomFieldsFeatureEnabled() {
      return this.isFeatureEnabled(['custom-fields']);
    },
    showCustomFields() {
      return this.arEnableCustomFields && this.isCustomFieldsFeatureEnabled && (this.type === 'orders' || this.type === 'audience')
    },
  },

  watch: {
    isMassImport(val) {
      if (val) {
        this.step = 0;
        this.type = 'mass';
      } else {
        this.type = 'audience';
      }
    },
    selectedProvider(val) {
      if (!val) {
        this.providerSpecificMap = [];
        this.providerReminder = true;
        return;
      }
      this.providerReminder = false;
      const foundDefaultMapping = this.defaultProviders.find((item) => item.name.toLowerCase() === val.toLowerCase());
      if (!foundDefaultMapping) return;
      this.providerSpecificMap = (foundDefaultMapping?.customMappings || []).filter(
        (item) => item.enabledFor.indexOf(this.type) > -1
      );
    },
    selectedCurrency(val) {
      if (val) {
        this.currencyMissing = false;
      } else {
        this.currencyMissing = true;
      }
    },
    canImport(val, oldVal) {
      if (val && !oldVal) {
        this.clearErrors();
      }
    },
    eventTimeZone(val, oldVal) {
      if (val && val !== oldVal) {
        this.selectedTimezone = val;
      }
    },
    currentRegionSettings() {
      this.selectedTimezone = this.eventTimeZone || this.getSelectedTimezone()

      if (!this.selectedCurrency && !!this.currentRegionSettings?.additionalInfo?.currency) {
        this.selectedCurrency = { name: this.currentRegionSettings?.additionalInfo?.currency }
      }
    }
  },
  created() {
    this.arToPromoterMap = initialAudienceCSVMap();
    this.providerSpecificMap = [];

    window.addEventListener('arModalOpen', this.handleModalOpen);
    this['customField/FETCH_CUSTOM_FIELDS']({type: 'profile'})
    this['auth/FETCH_REGION_SETTINGS']();
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    ...mapActions([
      'IMPORT_EVENT_DATA',
      'OPEN_FAILED_IMPORT_MODAL',
      'FETCH_CUSTOM_PROVIDERS',
      'CREATE_CUSTOM_PROVIDER',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'OPEN_IMPORT_PRODUCT_DATA_MODAL',
      'customField/FETCH_CUSTOM_FIELDS',
      'auth/FETCH_REGION_SETTINGS',
    ]),

    toggleProductImportModel(option) {
      if (this.isOneSchemaEnabled) {
        this.selectType(option);
      } else {
        // set the event modal invisible, is it good?
        this.isVisible = !this.isVisible;
        this.OPEN_IMPORT_PRODUCT_DATA_MODAL({
          isMassImport: false,
        });
      }
    },

    optionTitleDescription(option, isTitle) {
      //option is in  ['events', 'orders', 'events and orders', 'POS-line-items'],
      // isTitle true or false
      if (isTitle) {
        switch (option) {
          case 'events':
            return 'Events only';
          case 'orders':
            return 'Import ticket orders';
          case 'events and orders':
            return 'Events and ticket orders';
          case 'POS-line-items':
            return 'Events and POS orders';
          default:
            return 'Invalid option';
        }
      } else {
        switch (option) {
          case 'events':
            return 'Import one or multiple events';
          case 'orders':
            return 'Import ticket orders against a single event';
          case 'events and orders':
            return 'Import multiple events and ticket orders';
          case 'POS-line-items':
            return 'Import multiple events and POS orders';
          default:
            return 'Invalid option';
        }
      }
    },

    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_IMPORT_EVENT_DATA_MODAL') return;
      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(
        new CustomEvent('arModalResponse', { detail: { action: 'OPEN_IMPORT_EVENT_DATA_MODAL', payload } })
      );
    },
    async startModalAction({ eventOid, eventTimeZone, prefetch, isMassImport }) {
      await this.getProviders();
      this.isVisible = true;
      this.eventOid = eventOid || null;
      this.eventTimeZone = eventTimeZone || null;
      this.isMassImport = isMassImport || false;

      this.selectedTimezone = this.eventTimeZone || this.getSelectedTimezone()

      if (prefetch) {
        this.step = 2;
        this.csvHeaders = prefetch.headers;
        this.csvRows = prefetch.body;
        this.type = 'audience';
      } else if (this.isMassImport) {
        this.step = 0;
      } else {
        this.step = 1;
      }
      this.onConfirm = () => {
        this.isVisible = false;
        this.respondModalAction(true);
        this.reset();
      };
      this.onClose = () => {
        this.type = this.isMassImport ? 'mass' : 'audience';
        this.step = this.isMassImport ? 0 : 1;
        this.isVisible = false;
        this.reset();
        this.respondModalAction(false);
      };
    },
    reset() {
      this.providerSpecificMap = [];
      this.csvHeaders = [];
      this.csvRows = [];
      this.activeMapping = 'events';
      this.selectedCurrency = null;
      this.selectedProvider = null;
      this.error = '';
      this.rowErrors = false;
      this.indexesToValidate = [];
      this.selectedTimezone = null;
    },
    async getProviders() {
      const defaultProviders = clone(this.defaultProviders);
      if (defaultProviders.length > 0) {
        defaultProviders.unshift({
          name: 'Default providers',
          type: 'header',
        });
      }
      // Strip out any custom providers which share a name with default providers
      const customProviders = (await this.FETCH_CUSTOM_PROVIDERS())
        .map((p) => {
          return {
            name: capitalizeFirstLetter(p.provider),
            key: p.provider.toLowerCase(),
            value: p.oid,
            columnMapping: p.meta.columnMap || null,
          };
        })
        .filter((item) => {
          return (
            this.defaultProviders
              .map((defaultProvider) => defaultProvider.name.toLowerCase())
              .indexOf(item.name.toLowerCase()) === -1
          );
        });
      if (customProviders.length > 0) {
        customProviders.unshift({
          name: 'Custom providers',
          type: 'header',
        });
      }
      this.availableProviders = defaultProviders.concat(customProviders);
    },
    handleCSVUploaded({ file, additionalInfo: { headers, body } }) {
      this.csvHeaders = headers;
      this.csvRows = body;
      this.importFile = new File([file], sanitizeFilename(file.name), { type: file.type});
      this.indexesToValidate = this.csvRows.length < 1000
        ? Array.from({length: this.csvRows.length}, (_, i) => i)
        : generateUniqueIntegersArray(500, 0, this.csvRows.length - 1)
      this.step = 2;
    },
    changeMapping(tabItem) {
      this.activeMapping = tabItem.id;
    },
    toggleProviderChooser() {
      this.isProviderChooserOpen = !this.isProviderChooserOpen;
    },
    previousStep() {
      if (this.step === 10) {
        this.step = 0
      } else {
        this.step -= 1;
      }
      this.typeMapper();
      this.reset(); // this will remove arToPromoterMap relatesTo the active mapping.
    },
    typeMapper() {
      this.providerSpecificMap = [];
      if (this.type === 'events') {
        this.arToPromoterMap = initialEventsCSVMap();
      } else if (this.type === 'orders') {
        this.arToPromoterMap = initialAudienceCSVMap();
        this.arToPromoterMap.push({
          value: 'Event ID',
          required: true,
          serverHeaderName: 'eventId',
          mappedTo: null,
          columnType: 'string',
        });
      } else if (this.type === 'events and orders') {
        const interimArToPromoterMap = initialEventsCSVMap().map((e) => {
          e.relatesTo = 'events';
          return e;
        });

        this.arToPromoterMap = interimArToPromoterMap.concat(
          initialAudienceCSVMap().map((a) => {
            a.relatesTo = 'orders';
            return a;
          })
        );
      }
    },
    selectType(type) {
      this.type = type;
      if (this.isOneSchemaEnabled
          && (this.type === 'events'
              || this.type === 'events and orders'
              || this.type === 'ticket_orders_single_event'
              || this.type === 'POS-line-items')) {
        this.typeOfImportModal = type;
        this.step = 10;
      } else {
        this.typeMapper();
        this.step = 1;
      }
    },
    selectProvider(providerObj) {
      this.providerSpecificMap = [];

      const providerName = providerObj.name.toLowerCase();
      this.selectedProvider = providerName;

      // RK/NOTE:
      // We don't want to prefill the column mappings for now.
      // if (
      //   providerObj.columnMapping &&
      //   this.arToPromoterMap.every(a => a.mappedTo === null || a.mappedTo === undefined)
      // ) {
      //   // columnMaps are stored as kv pairs where k is an index and v is a server column name
      //   const mapping = providerObj.columnMapping;
      //   for (var columnIndex in mapping) {
      //     const serverHeaderName = mapping[columnIndex];
      //     const arMappingIndex = this.arToPromoterMap.findIndex(
      //       a => a.serverHeaderName === serverHeaderName
      //     );
    },
    selectCurrency(val, arTableHeader) {
      this.selectedCurrency = val;
    },
    selectDateformat(val) {
      this.selectedDateFormat = val;
    },
    setMapping(val, arTableHeader, isProviderSpecificMapping) {
      if (isProviderSpecificMapping) {
        const arTableHeaderIndex = this.providerSpecificMap.indexOf(arTableHeader);
        this.providerSpecificMap[arTableHeaderIndex].mappedTo = val.index;
        this.providerSpecificMap[arTableHeaderIndex].error = undefined;
      } else {
        const arTableHeaderIndex = this.arToPromoterMap.indexOf(arTableHeader);
        this.arToPromoterMap[arTableHeaderIndex].mappedTo = val.index;
        this.arToPromoterMap[arTableHeaderIndex].error = undefined;
      }
    },
    csvHeadersOptions(selectedHeaderIndex) {
      return this.csvHeaders
        .map((c, index) => ({
          name: c,
          index,
        }))
        .filter((c) => {
          return this.arToPromoterMap.find((item) => item.mappedTo === c.index && c.index !== selectedHeaderIndex) === undefined;
        });
    },
    actualSelectedIndex(selectedHeaderIndex) {
      if (selectedHeaderIndex === null) return null;

      const value = this.csvHeaders[selectedHeaderIndex]
      const newIndex = this.csvHeadersOptions(selectedHeaderIndex).findIndex((c) => c.name === value);

      return newIndex || 0
    },
    actualSelectedCurrencyIndex(selectedHeaderIndex) {
      const selectedIndex = this.actualSelectedIndex(selectedHeaderIndex);
      if (selectedIndex > -1) {
        return selectedIndex
      } else if (this.currentRegionSettings) {
        const currencyCode = this.currentRegionSettings?.additionalInfo?.currency
        if (!!currencyCode) {
          const index = this.currencyOptions.findIndex((c) => c.name === currencyCode);
          if (index > -1) {
            return index
          }
        }
      }

      return null
    },
    clientToServerMapping(mapping) {
      const cleanedColumnMap = {};

      const preMapping = this.arToPromoterMap
        .filter((a) => a.mappedTo !== null)
        .concat(this.providerSpecificMap.filter((a) => a.mappedTo !== null));

      preMapping.forEach((a) => {
        cleanedColumnMap[a.mappedTo] = a.serverHeaderName;
      });

      return cleanedColumnMap;
    },
    async createProvider(provider) {
      const currentMapping = this.clientToServerMapping();
      const providerName = provider.toLowerCase();
      const payload = {
        provider: providerName,
        mapping: currentMapping,
      };

      if (this.defaultProviders.some((item) => item.name.toLowerCase() === providerName)) {
        this.$arNotification.push({
          type: 'warning',
          message: 'Default provider already exists with this name',
          link: null,
          timeout: 5000,
        });
        return;
      }

      const result = await this.CREATE_CUSTOM_PROVIDER(payload);
      if (result) {
        this.availableProviders.push({
          name: capitalizeFirstLetter(result.provider),
          key: result.provider.toLowerCase(),
          value: result.oid,
          columnMapping: result.meta.columnMap || null,
        });

        this.selectedProvider = result.provider;
      }
    },
    clearErrors() {
      // Remove errors from mapping arrays
      this.providerReminder = false;
      this.currencyMissing = false;
      this.arToPromoterMap = clone(
        this.arToPromoterMap.map((mapping, index) => {
          mapping.error = null;
          return mapping;
        })
      );
      this.providerSpecificMap = clone(
        this.providerSpecificMap.map((mapping, index) => {
          mapping.error = null;
          return mapping;
        })
      );
    },
    async beginImport() {
      this.clearErrors();
      this.isImportingData = true;
      const incompleteRows = [];
      const incompleteProviderSpecificRows = [];
      this.arToPromoterMap.forEach((a, index) => {
        if (a.required && (a.mappedTo === null || a.mappedTo === -1)) {
          incompleteRows.push(index);
        }
        if (a.value === "Timezone" && a.required && !this.selectedTimezone) {
          incompleteRows.push(index)
        }
      });
      this.providerSpecificMap.forEach((a, index) => {
        if (a.required && (a.mappedTo === null || a.mappedTo === -1)) {
          incompleteProviderSpecificRows.push(index);
        }
      });

      let flagMissingField = false;
      if (this.selectedProvider === null) {
        // move the errors below provider chooser
        this.providerReminder = true;
        flagMissingField = true;
      } else {
        this.providerReminder = false;
      }

      if (this.type !== 'events' && this.selectedCurrency === null) {
        flagMissingField = true;
        this.currencyMissing = true;
      } else {
        this.currencyMissing = false;
      }

      if (this.csvRows.length === 0) {
        this.error = 'Your CSV has no rows';
      }

      if (incompleteRows.length > 0 || flagMissingField || incompleteProviderSpecificRows.length > 0) {
        let mapWithErrors = this.arToPromoterMap.map((a, index) => {
          if (incompleteRows.includes(index)) {
            a.error = 'This field is required.';
          }
          return a;
        });
        let mapWithErrors2 = this.providerSpecificMap.map((a, index) => {
          if (incompleteProviderSpecificRows.includes(index)) {
            a.error = 'This field is required.';
          }
          return a;
        });

        this.arToPromoterMap = mapWithErrors;
        this.providerSpecificMap = mapWithErrors2;
        this.isImportingData = false;
      } else {
        let validationFailures = '';

        // Pre-validation check.
        this.arToPromoterMap.forEach((mapping) => {
          if (mapping.mappedTo !== null && csvValidationCheck(mapping, this.csvRows, false, this.selectedDateFormat.val, this.indexesToValidate) != null) {
            mapping.error = `Your CSV has an invalid value for this column.`;
            validationFailures += `<strong>Warning: One or more rows have errors.</strong><p>The ${
              mapping.value
            } column has an invalid value:</p><code style="white-space: pre">${csvValidationCheck(
              mapping,
              this.csvRows,
              false,
              this.selectedDateFormat.val,
              this.indexesToValidate
            )}</code> <br/>`;
          }
        });
        this.providerSpecificMap.forEach((mapping) => {
          if (mapping.mappedTo !== null && csvValidationCheck(mapping, this.csvRows, false, this.selectedDateFormat.val, this.indexesToValidate) != null) {
            mapping.error = `Your CSV has an invalid value for this column.`;
            validationFailures += `<strong>Warning: One or more rows have errors.</strong><p>The ${
              mapping.value
            } column has an invalid value:</p><code style="white-space: pre">${this.csvValidationCheck(
              mapping,
              this.csvRows,
              false,
              this.selectedDateFormat.val,
              this.indexesToValidate
            )}</code> <br/>`;
          }
        });

        if (!this.rowErrors &&
          (this.arToPromoterMap.some((a) => a.error) || this.providerSpecificMap.some((a) => a.error))) {
          this.error = validationFailures;
          this.isImportingData = false;
          this.rowErrors = true;
          return;
        }

        // let serverImportType = 'event';
        let serverImportType = 'audience-sales';

        if (this.isMassImport) {
          switch (this.type) {
            case 'orders':
              serverImportType = 'audience';
              break;
            case 'events':
              serverImportType = 'event';
              break;
            default:
              serverImportType = 'event-and-audience';
              break;
          }
        }
        // upload string with following method
        // const csvContent = (await this.importFile.text({ encoding: 'utf8' }));
        const csvContent = await fileContentZipToB64(this.importFile);
        const s3payload = {
          importType: serverImportType, // should be pure contact only
          originalCsvName: this.importFile.name,
          originalCsv: csvContent,
        };

        const serverResponse = await this.$api.buckets.uploadCsv(this.promoterOid, s3payload);
        if (serverResponse === null) {
          this.isImportingData = false;
          return;
        } else if (serverResponse.error) {
          this.error = serverResponse.error;
          this.isImportingData = false;
          return;
        } else if (!serverResponse.data['bucket-oid']) {
          this.error = 'The server has not received your csv file. No bucketOid';
          this.isImportingData = false;
          return;
        }

        const payload = {
          csvHeaders: this.csvHeaders,
          isMassImport: this.isMassImport,
          columnMap: this.clientToServerMapping(),
          importType: serverImportType,
          eventOid: this.eventOid,
          provider: this.selectedProvider,
          currencyCode:
            this.isMassImport && serverImportType === 'event'
              ? null
              : this.currencyOptions[this.selectedCurrencyIndex].name,
          dateFormat: this.selectedDateFormat.val,
          timeZone: this.selectedTimezone ? this.selectedTimezone : this.eventTimeZone,
          bucketOid: serverResponse.data['bucket-oid'],
        };

        try {
          const response = await this.IMPORT_EVENT_DATA(payload);
          this.updatePendingTasks();
          this.onConfirm();
          scroll(0, 0); //back to top of page to view the notification

          // const notificationType = response.type === 'ACCEPTED' ? 'success' :
          //   response.type === 'PARTIAL' ? 'warning' : 'error';
          const notificationType =
            response.type === 'ACCEPTED'
              ? 'warning' // for now, only show warning as default
              : response.type === 'PARTIAL'
              ? 'warning'
              : 'error';

          const notificationPayload = {
            type: notificationType,
            message: response.message,
            link: response.type === 'PARTIAL' ? 'Click here for more info' : null,
            timeout: 5000,
          };

          if (response.type === 'PARTIAL') {
            const answer = await this.$arNotification.push(notificationPayload);

            if (answer) {
              this.OPEN_FAILED_IMPORT_MODAL(response.failedRows);
            }
          } else {
            this.$arNotification.push(notificationPayload);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isImportingData = false;
        }
      }
    },
    updatePendingTasks() {
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    },

    addCustomFieldToFieldMap(data) {
      /* 16 Aug 2023 ADR
        There are two types of fields: default fields and custom fields.
        Default fields have been predefined by our system. Custom Fields are any field that promoters create on their own.
        Both can be imported into fan data.
        Note: Only Profile Custom Fields are allowed to be imported into a fan data. Form Custom Fields are not allowed.

        We identify each custom field input as "cf_$OID". It ensures that the server header name is unique and easily identifiable.
        It is the column name template used in the CSV when exported.
        The $OID value is the OID in the promoterCustomField in the backend.
      */
      this.arToPromoterMap.push({
        value: null,
        name: data.name,
        required: false,
        serverHeaderName: `cf_${data.oid}`,
        columnType: data.fieldType,
        mappedTo: null,
        hint: data.fieldType === 'date' ? "Recommended format DD/MM/YYYY" : null,
        customField: true,
      })
    },

    handleDeleteCustomField(val) {
      const customFieldIndex = this.arToPromoterMap.findIndex((field) => {
        return field.serverHeaderName === val.serverHeaderName
      })
      this.arToPromoterMap.splice(customFieldIndex, 1);
    },

    getSelectedTimezone() {
      return this.currentRegionSettings?.additionalInfo?.defaultTimezone || null
    }
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: $red500;
  font-size: small;
  padding-top: 0.5em;
  padding-left: 0.4em;
}

.import-data-modal {
  .mapping-instructions {
    background-color: $skyBlueGrey300;
    padding: 20px;

    &__subtitle {
      font-size: 12px;
      color: $blueGrey700;
    }
  }

  .tabs {
    padding: 0 20px;
    background-color: $skyBlueGrey300;
  }

  &__wrapper_preamble {
    text-align: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 23px 28px 28px;
    overflow: auto;

    &.xs-max {
      padding: 23px 12px 28px;
    }

    &.eventsAndOrders {
      max-height: calc(70vh - 120px);

      &.xs-max {
        max-height: calc(70vh - 55px);
      }
    }

    &.notEventsAndOrders {
      max-height: calc(70vh - 90px);

      &.xs-max {
        max-height: calc(70vh - 35px);
      }
    }

    &.generic {
      padding: 0px;
    }

    > .mass-import-option {
      min-height: 86px;
      border-top: 1px solid $blueGrey500;
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      &.xs-max {
        padding: 24px 12px;
      }

      > div {
        padding: 0.75rem;

        > p.heading {
          color: #43516b;
          font-size: 16px;
        }

        > p.body {
          color: #8e97a6;
        }

        &:last-child {
          position: absolute;
          right: 24px;

          &.xs-max {
            right: 12px;
          }
        }
      }

      &:hover {
        cursor: pointer;
        background-color: darken(white, 2%);
      }
    }

    p.column-text {
      color: $blueGrey700;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      border-bottom: 1px solid $skyBlueGrey500;
      padding-bottom: 13px;

      span:last-child {
        float: right;
      }
    }

    &__import-message {
      font-size: 1.2em;
      padding-top: 1em;
    }

    &_preamble {
      margin-bottom: 1em;
    }

    .form {
      padding-bottom: 28px;

      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: space-between;
        align-items: center;
        margin-top: 0.75em;

        &__label {
          font-weight: bold;
          width: 40%;
          position: relative;

          .ar-icon-wrapper {
            position: absolute;
            right: 8px;
            margin-bottom: 0;
            bottom: 8px;
          }
        }

        &__input {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 60%;

          .selector {
            width: 100%;
          }
        }

        > :first-child {
          flex: 1;
        }

        > :nth-child(2) {
          flex: 3;
        }

        .secondary-dropdown {
          margin-left: 15px;
        }
      }

      .custom-field-map {
        border-top: 1px solid $skyBlueGrey400;
        margin-top: 20px;
        padding-top: 10px;

        .form-row__label {
          min-width: 0;

          &__display {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
        }

        .form-row__input {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .simple-button-filtered-dropdown-wrapper {
        margin-top: 20px;
      }
    }
  }

  &__footer {
    .footer-container {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      background-color: $skyBlueGrey300;
      position: relative;
      z-index: $zIndexHighest;

      .footer-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 50px;
        padding: 28px 28px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      p {
        text-align: center;
        color: $blueGrey800;

        &.header {
          font-family: Graphik-Medium;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0;
        }

        &.body {
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      div.error-message {
        border: 1px solid $orange500;
        background: $orange-lite;
        transition: all 0.3s ease;
        color: #42516b;
        border-radius: 5px;
        padding: 12px 20px;
        text-align: left;
        margin: 0 28px 0 28px;
      }

      .tick-container {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: $green500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__type,
  &__action,
  &__get-started {
    width: 50%;
  }

  &__type,
  &__action,
  &__get-started {
    padding: 25px 35px;
  }

  &__type {
    background-color: $skyBlueGrey300;
    height: 100%;
  }

  &__button {
    width: 100%;
    margin-top: 42px;
  }

  &__checkbox {
    margin-top: 15px;
  }

  &__footer-wrapper {
    display: flex;
    height: 50px;
    box-shadow: 0 3px 10px;
    padding: 50px;
    align-items: center;
    justify-content: flex-end;
  }

  &__eventbrite {
    text-decoration: underline;
    margin-top: 15px;
  }

  div.ticketing-provider {
    display: flex;
    align-items: center;

    &.xs-max {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .provider-chooser {
      height: 14px;
      margin-left: 5px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .icon-container {
    background: $skyBlueGrey400;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    min-height: 60px;

    &.xs-max {
      width: 44px;
      height: 44px;
      min-width: 44px;
      min-height: 44px;
    }
  }
}
</style>
