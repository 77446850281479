var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreMessageLists),expression:"fetchMoreMessageLists"}],staticClass:"message-lists-page-wrapper",attrs:{"infinite-scroll-disabled":"disableFetchMoreMessageLists","infinite-scroll-distance":10}},[_c('am2-create-message-list-modal',{attrs:{"is-show":_vm.displayCreateMessageListModal},on:{"close":_vm.handleCreateMessageListModalClose}}),_vm._v(" "),_c('am2-message-list-preferences-modal',{attrs:{"is-show":_vm.displayMessageListPreferencesModal},on:{"close":_vm.handleMessageListPreferencesModalClose}}),_vm._v(" "),_c('div',{class:( _obj = {
      content: true
    }, _obj['content'] = true, _obj )},[_c('am2-top',{class:[
        'top-wrapper',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"expand-icon-button":{
        iconName: 'settings',
        text: 'Settings',
        'v-tooltip.bottom': {
          content: 'List settings'
        },
        action: _vm.handleSettingsClick
      },"searchLeft":{
        action: _vm.serverSideSearch,
        placeholder: 'Search lists',
        value: _vm.searchString,
        style: {
          width: _vm.$arMediaQuery.pageContent.minWidth('md') ? '330px' : '100%'
        }
      }}}),_vm._v(" "),_vm._l((_vm.messageLists),function(list){return _c('div',{key:list.oid,style:({ marginBottom: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '12px' : '20px' })},[_c('am2-messaging-list-card',{attrs:{"messaging-list":list,"show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list',"progress":_vm.getProgressForList(list.oid)}})],1)}),_vm._v(" "),(!_vm.isFetchingMessageLists && _vm.messageLists.length === 0)?_c('div',[(_vm.searchString)?_c('am2-no-content-section',{attrs:{"header":"No lists found","header-size":"sm"}}):_c('am2-no-content-section',{attrs:{"icon-name":"ar-list-in-circle","header":"Create a List","body":"Lists are where your subscribers are managed across email and SMS","button-text":"Create a list"},on:{"buttonClick":_vm.handleCreateListClick}})],1):_vm._e(),_vm._v(" "),_c('div',{style:({
        height: '80px',
      })},_vm._l((_vm.skeletonCardsCount),function(n){return _c('am2-card-skeleton',{key:n,style:({
          marginBottom: '20px',
          width: '100%',
          border: '1px solid #EDF1F5',
        }),attrs:{"level":2 * n - 1,"type":"message-list","show-as":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'}})}),1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }